<template>
  <div id="scene" :style="GetDisplay"></div>
</template>

<script>

import UISectionMixin from '@/Mixins/UISectionMixin';



export default {
  name: 'XRScene',
  data(){
    return {
      canvasController : null,
      value : .5,
      isLoading : false
    }
  },
   computed: {
    GetDisplay: function(){
      return {        
        display: this.$route.name === "Home" || !this.$store.state.world.sceneReady ? "none" : "block"
      }
    }
  },
  mounted(){
    // if(this.$store.state.world.mainScene == null){
    //   var xr = new MainScene({
    //     domElement : "scene",
    //     canvasElement : "canvas-holder",
    //     store : this.$store,
    //     router : this.$router
    //   });
    // }

  },
  methods : {

  }
}
</script>

<style lang="scss">

@import "../styles/imports.scss";
@import "../styles/range-slider.scss";

#scene{
  //z-index: -2;
  z-index: 0;
}


</style>